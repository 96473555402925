import Web3 from "web3";
import axios from "axios";
import { abi } from "../abi/Erc721abi.json";

// import { getContract } from "../getContract";
import { utils } from "ethers";
let web3;
export const getContract = async (tokenAddress, provider) => {
  let contract;
  //console.log('token address', tokenAddress)
  web3 = new Web3(provider);

  try {
    contract = new web3.eth.Contract(abi, tokenAddress);
    console.log("contract", contract);

    return contract;
  } catch (error) {
    console.log("getContract Error", error);
  }
};

export const mint = async (
  contractAddress,
  accountAddress,
  provider,
  nftPrice
) => {
  let contract;
  try {
    contract = await getContract(contractAddress, provider);
    console.log("contract", contract);
    const response = await contract.methods
      .mint("1")
      .send({
        // value: web3.utils.toWei("0.01"),

        value: web3.utils.toWei(nftPrice.toString()),
        from: accountAddress,
      })
      .on("transactionHash", (hash) => {
        console.log({ hash });
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        // if (confirmationNumber === 1) {
        // }
      });
    console.log({ response });
  } catch (e) {
    console.log(e);
  }
};

// export const getDecimal = async (tokenAddress) => {
//   try {
//     const contract = await getContract(tokenAddress);
//     const decimals = await contract.methods.decimals().call();
//     const parsedDecs = await utils.formatUnits("10000000", decimals);
//     //console.log(parsedDecs);
//     return [decimals, parsedDecs];
//   } catch (e) {
//     console.log(e);
//   }
// };
// export const getName = async (tokenAddress) => {
//   try {
//     const contract = await getContract(tokenAddress);
//     const name = await contract.methods.name().call();
//     //console.log(name);
//     return name;
//   } catch (e) {
//     console.log(e);
//   }
// };

// export const getSymbol = async (tokenAddress) => {
//   try {
//     const contract = await getContract(tokenAddress);
//     const symbol = await contract.methods.symbol().call();
//     return symbol;
//   } catch (e) {
//     console.log(e);
//   }
// };

// export const getWei = async (tokenAddress) => {
//   try {
//     const contract = await getContract(tokenAddress);
//     const symbol = await contract.methods.totalSupply().call();
//     console.log(symbol);
//     return symbol;
//   } catch (e) {
//     console.log(e);
//   }
// };

// //get token logo
// export const getTokenLogo = async (tokenAddress) => {
//   console.log(tokenAddress);
//   const unknownLogo =
//     "https://gateway.pinata.cloud/ipfs/QmNX2QerTxTm1RThD7Dc9X5uS9VFnQxmMotaMFhK5GYbBk";
//   try {
//     const logoUrl = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${tokenAddress}/logo.png`;
//     const res = await axios.get(logoUrl);
//     if (res) {
//       return logoUrl;
//     } else {
//       return unknownLogo;
//     }
//   } catch (error) {
//     console.log(error);
//     return unknownLogo;
//   }
// };
