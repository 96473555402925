export const WhiteJadeContractAddress =
  "0x75ca0D622810C6A20BfD77BCEeB4885bEcAE0ef0";
export const YellowJadeContractAddress =
  "0x54111b0BF15CD3fd404970BBEBcF178beEaDb521";
export const GreenJadeContractAddress =
  "0x86c9F6e5F151261310031DEFfB688870Da8deCB4";
export const BlueJadeContractAddress =
  "0x0cc9aAb355805e2C560Fe974a61C02D41DfCE32A";
export const RedJadeContractAddress =
  "0xE1F44A4B35A5cB9FB61571A048ee9e98f0Fd6048";


// Mumbai Testnet

// export const WhiteJadeContractAddress =
//   "0x86c9F6e5F151261310031DEFfB688870Da8deCB4";
// export const YellowJadeContractAddress =
//   "0x0cc9aAb355805e2C560Fe974a61C02D41DfCE32A";
// export const GreenJadeContractAddress =
//   "0x96F2818c576A69ebCD250C3711Cc6b92899644A5";
// export const BlueJadeContractAddress =
//   "0xF375460ceE5661E0bEC042424eB509e7f4Fd0580";
// export const RedJadeContractAddress =
//   "0x8339FFF4C7EA27509bF64b5aBdB190c86572C100";