import React, { useState } from "react";
import { Box, Paper, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import { mint, getContract } from "../contract/functions/ERC20functions";
// import { getContract } from "./../contract/getContract";
import {
  BlueJadeContractAddress,
  WhiteJadeContractAddress,
  YellowJadeContractAddress,
  GreenJadeContractAddress,
  RedJadeContractAddress,
} from "./../contract/constants";
export default function NftCard({
  setNftIndex,
  setStep,
  nftImage,
  nftHeading,
  nftDescription,
  step,
  nftIndex,
  nftPrice,
  web3Here
}) {
  const web3Context = useWeb3React();
  // const [web3Data, setWeb3data] = useState();
  console.log("HEREEEEEEEEEE", web3Here);

  // React.useEffect(() => {
  //   setWeb3data(web3Context);
  //   console.log("HEREEEEEEEEEE", web3Context.account);
  // }, [web3Context]);
  const onClickHandler = async () => {
    console.log({ nftPrice, web3Context });

    if (step == 2) {
      if (nftPrice === 90)
        mint(
          WhiteJadeContractAddress,
          web3Here.account,
          web3Here?.library?.currentProvider,
          nftPrice
        );
      if (nftPrice === 180)
        mint(
          YellowJadeContractAddress,
          web3Context.account,
          web3Context?.library?.currentProvider,
          nftPrice
        );
      if (nftPrice === 360)
        mint(
          GreenJadeContractAddress,
          web3Context.account,
          web3Context?.library?.currentProvider,
          nftPrice
        );
      if (nftPrice === 720)
        mint(
          BlueJadeContractAddress,
          web3Context.account,
          web3Context?.library?.currentProvider,
          nftPrice
        );
      if (nftPrice === 1440)
        mint(
          RedJadeContractAddress,
          web3Context.account,
          web3Context?.library?.currentProvider,
          nftPrice
        );
      console.log("Jhoole lal");
    } else {
      // setStep(2);
      if (nftPrice === 90)
        window.open("https://whitejade.sageverse.online", "_blank");

      if (nftPrice === 180)
        window.open("https://yellowjade.sageverse.online", "_blank");

      if (nftPrice === 360)
        window.open("https://greenjade.sageverse.online", "_blank");

      if (nftPrice === 720)
        window.open("https://bluejade.sageverse.online", "_blank");

      if (nftPrice === 1440)
        window.open("https://bloodjade.sageverse.online", "_blank");

      setNftIndex(nftIndex);
    }
  };
  // whitejade.sageverse.online;
  // yellow.sageverse.online;
  // green.sageverse.online;
  // blood.sageverse.online;
  // blue.sageverse.online;
  return (
    <Box
      sx={{ flexGrow: 1 }}
      className="main-nft-box"
      style={{
        padding: "5%",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.9)",
        }}
      />
      <Grid
        container
        spacing={2}
        // direction="column"
        // alignItems="center"
        // justifyContent="center"
        style={{
          position: "relative",
          zIndex: 999,
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={nftImage} width="70%" />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          style={
            {
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            }
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              //   justifyContent: "left",
              //   alignItems: "center",
              position: "relative",
              zIndex: 999,
              color: "white",
            }}
          >
            <p
              style={{
                fontFamily: "Space Ranger",

                fontSize: 24,
              }}
            >
              {nftHeading}
            </p>
            {/* <p>
              Loirm ipsam is a collection of 10,000 unique, randomly generated
              wild boars roaming on the Ethereum blockchain as ERC-721 NFTs.
            </p> */}
            <p>{nftDescription}</p>
            <div
              style={{
                display: "flex",
                height: "40px",
                alignItems: "center",
              }}
            >
              <p>Price:</p>{" "}
              <p
                style={{
                  fontFamily: "Space Ranger",
                  marginLeft: 10,
                  fontSize: 24,
                }}
              >
                {nftPrice} MATIC
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",

                alignItems: "center",
              }}
            >
              {step === 2 && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "orangered",
                    color: "white",
                    fontWeight: "bold",
                    textTransform: "none",
                    width: "33%",
                    marginTop: "35px",
                    // marginLeft: "40px",
                    marginRight: "40px",
                  }}
                  onClick={() => {
                    window.open("http://sageverse.online/");
                  }}
                >
                  Cancel
                </Button>
              )}

              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "green",
                  fontWeight: "bold",
                  textTransform: "none",
                  width: "33%",
                  marginTop: "35px",
                }}
                onClick={() => {
                  onClickHandler();
                }}
              >
                Mint
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
