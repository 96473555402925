import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";

import Navbar from "./components/Navbar";
import Landing from "./Pages/Landing";

import { Web3ReactProvider } from "@web3-react/core";

import { getLibrary } from "./utils/getLibrary";
import ConnectWallet from "./components/ConnectWallet";

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      {/* <ThemeProvider theme={theme}> */}
      {/* <Layout> */}

      <Routes>
        {/* <Switch> */}
        <Route path="/" element={<Landing />} />

        {/* <Route path="/nft" element={<SingleNftPage />} /> */}
        {/* </Switch> */}
      </Routes>
      {/* </Layout> */}
      <Navbar />

    </Web3ReactProvider>
  );
}

export default App;
